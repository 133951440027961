import React from 'react';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, Typography, Badge, Grid, IconButton, Menu, MenuItem, Card, CardHeader, CardContent } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PushPinIcon from '@mui/icons-material/PushPin';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import TenantDropdown from './NavigationBarTenantDropdown';

import { useAuth } from '../contexts/AuthContext';
import { useAppContext } from '../contexts/AppContext';

import config from '../config';
const drawerWidth = config.ui_settings.drawer_width;//240;

const CustomAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBarComponent = ({ open, handleDrawerOpen, title /* selectedIndex, menuItems_front_end */ }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { logout } = useAuth();
    const { userData } = useAppContext();

    const handleLogout = () => {
      logout(); // Logout the user, redirect
    };

    // Navigation ---------------------------------------
    const isAccountMenuOpen = Boolean(anchorEl);
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
   
    const profile_menu_id = 'account-menu';

    const handleAccountMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const renderAccountMenu = (
        <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        id={profile_menu_id}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        open={isAccountMenuOpen}
        onClose={handleMenuClose}
        >
            {/* Conditional rendering of user's name and email */}
            {userData && (
                <Card>
                    <CardHeader title="Benutzer Details" />
                    <CardContent>
                        <Typography variant="body1" component="div">
                            <b>Name:</b> {userData ? userData.user : 'Lädt...'}
                        </Typography>
                        <Typography variant="body1" component="div">
                            <b>Email:</b> {userData ? userData.email : 'Lädt...'}
                        </Typography>
                    </CardContent>
                </Card>
            )}
            <MenuItem onClick={handleLogout}>
                <ExitToAppIcon sx={{ marginRight: 1 }} /> Logout
            </MenuItem>
        </Menu>
    );

    return (
    <CustomAppBar position="fixed" open={open} sx={{ /* borderBottom: '5px solid darkblue', */ backgroundColor: 'white', color: 'black' }} > {/* #dc0018 */}
        <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
                }}
            >
                <MenuIcon />
            </IconButton>
            <Grid container spacing={2} alignItems="left"> {/* Adjust the spacing value as needed */}
                <Grid item>
                    <TenantDropdown />
                </Grid>
                <Grid item>
                    <Typography variant="h6" sx={{ borderLeft: 2, pl: 2 }} noWrap component="div">
                    {/* {menuItems_front_end[selectedIndex]} */}{title}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item  container direction={'row'} sx={{ margin: 'auto', marginRight: 0, flexGrow: 1, alignSelf: 'flex-end', justifyContent: 'end' }}>
                {/* <IconButton disabled size="large" aria-label="show 4 new pinned documents" color="inherit">
                    <Badge badgeContent={0} color="error">
                        <PushPinIcon />
                    </Badge>
                </IconButton> */}
                <IconButton disabled
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={0} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>

                <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={profile_menu_id}
                    aria-haspopup="true"
                    onClick={handleAccountMenuOpen}
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
            </Grid>
        </Toolbar>
        {renderAccountMenu}
    </CustomAppBar>
    );
};

export default AppBarComponent;
