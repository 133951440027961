import React, { createContext, useContext, useState } from "react";
/* import { Snackbar, Alert } from '@mui/material'; */
import { http } from '../https';
import config from '../config';
import dayjs from 'dayjs';
import axios from 'axios';
import { useApiErrorHandler } from '../hooks/useApiErrorHandler';
import { useAuth } from "./AuthContext";
const AppContext = createContext(null);


export const useAppContext = () => {
    return useContext( AppContext );
};

export const AppProvider = ({children}) => {
    /* const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState(''); */
    const handleApiError = useApiErrorHandler();

    /************************************************************************************* */

    /**
     * AUTH
     */
    const { token, userTenants, currentTenant, logout } = useAuth();

    const [userData, setUserData] = React.useState(null);
    const [userProjects, setUserProjects] = React.useState(null);
    const [userDataError, setUserDataError] = React.useState(null);
    const [isLoadingUserData , setIsLoadingUserData ] = React.useState(null);

    /** 
     * Navbar STATE
     */
    const [project, setProject] = React.useState('pr_id1');
    const [drawerOpen, setDrawerOpen] = React.useState(true);
    const [activeTab, setActiveTab] = React.useState(0);
    const [activePage, setActivePage] = React.useState(0);
    const [pageTabs, setPageTabs] = React.useState([]);

    /**
     * Task Predictions STATE
     */
    const [startDate, setStartDate] = React.useState(dayjs());
    const [endDate, setEndDate] = React.useState(dayjs());
    const [task_predictions, setTaskPredictions] = React.useState([]);
    const [tasksResultsLoading, setTasksResultsLoadingStatus] = React.useState(false);

    /**
     * Document Search STATE
     */
    const [query, setQuery] = React.useState(null);
    const [searchParams, setSearchParams] = React.useState({});
    const [doc_results, setDocResults] = React.useState([]);
    const [top_k, setTop_k] = React.useState(10);
    const [docsResultsLoading, setDocsResultsLoadingStatus] = React.useState(false);

    /**
     * Q&A LLM STATE
     */
    const [qa_docs, setQADocs] = React.useState([]);
    const [qa_history, setQAHistory] = React.useState([]);
    const [qa_results, setQAResults] = React.useState([]);
    const [qa_query_controller, setQAQueryController] = React.useState(null);
    const [qaQueryLoading, setQAQueryLoading] = React.useState(false);
    const [qaFeedbackMessage, setQAFeedbackMessage] = React.useState("");
    const [qaDeleteLoading, setQADeleteLoading] = React.useState(false);
    const [qaUploadLoading, setQAUploadLoading] = React.useState(false);
    const [qaReportCreationLoading, setQAReportCreationLoading] = React.useState(false);
    /* const [qaUploadLoading, setQAUploadLoading] = React.useState(false); */
    /*     const [qa_upload_controller, setQAUploadController] = React.useState(new AbortController()); */
    

    /**
     * ReportTemplates Management STATE
     */
    const [reportTemplates, setReportTemplates] = React.useState([]);
    const [reportTemplatesLoading, setReportTemplatesLoading] = React.useState(false);
    const [reportDefinitionData, setReportDefinitionData] = React.useState([]);

    /**
     * ReportInstances Management STATE
     */ 
    const [reportInstances, setReportInstances] = React.useState([]);
    const [reportInstancesLoading, setReportInstancesLoading] = React.useState(false);

    /**
     * Project Management STATE
     */
    const [projects, setProjects] = React.useState([]);
    const [projectsLoading, setProjectLoading] = React.useState(false);

    /************************************************************************************* */

    /** 
     * General METHODS
     */
    const getClientId = async() => {
        return 2;
    };

    const isDatePast = (inputDate) => {
        if (!inputDate) {
            //console.log( "No date provided. Please provide a valid date in the format 'YYYY-MM-DD'.");
            return null;
        }
    
        const today = new Date(); // Get today's date
        const givenDate = new Date(inputDate); // Convert input string to Date object
    
        // Check if the given date is valid
        if (isNaN(givenDate.getTime())) {
            //console.log ("Invalid date format. Please provide a valid date in the format 'YYYY-MM-DD'.");
            return null;
        }
    
        // Reset time part for both dates to ensure an accurate date-only comparison
        today.setHours(0, 0, 0, 0);
        givenDate.setHours(0, 0, 0, 0);
    
        if (givenDate < today) {
            //console.log ( "The given date is in the past.");
            return true;
        } else if (givenDate >= today) {
            //console.log ( "The given date is in the future, or today.");
            return false;
        } else {
            //console.log ( "The given date is invalid.");
            return false;
        }
    };

    /**
     * Calculated Data
     */
    const getCalculatedData = async (project_path) => {
        try {
            const response = await http.get(config.envs[config.active_env].domain + config.api_path + `projects/calculation`, {
            headers: {
                // Include the Authorization header with token
                Authorization: `Bearer ${token.accessToken}`,
                'Tenant': currentTenant,
                'Path': project_path
                }
            });

            let api_data = response.data;
            console.log('Data received:', api_data);
            
            return api_data;

        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "projects/calculation");
            return null;
            //setTasksResultsLoadingStatus(false);
        }
    };

    /**
     * Extracted Data
     */
    const getExtractedData = async (project_path) => {
        //setTasksResultsLoadingStatus(true);

        try {
            
            const response = await http.get(config.envs[config.active_env].domain + config.api_path + `projects/extract`, {
            headers: {
                // Include the Authorization header with token
                Authorization: `Bearer ${token.accessToken}`,
                'Tenant': currentTenant,
                'Path': project_path
                }
            });

            let api_data = Object.values(response.data);
            console.log('Extracted Data received:', api_data);
            let allTasks = [];
            let allSummaries = [];

            api_data.forEach(document => {
                document.extract.tasks.forEach(task => {
                    // Create a new task object with document attributes added and enforce fields
                    let taskWithDocumentInfo = {
                        text: task.text || "", // Enforce "text" field
                        assignee: task.assignee || "", // Enforce "assignee" field
                        due_date: task.due_date || "", // Enforce "due_date" field
                        document_id: document.file_id,
                        path: document.path,
                        file_name: document.file_name,
                        project: document.project,
                        tenant: document.tenant,
                        documents: [{ // 'documents' is an array that includes this document's info
                            document_id: document.file_id,
                            path: document.path,
                            file_name: document.file_name,
                            project: document.project,
                            tenant: document.tenant,
                        }], 
                        status: isDatePast(task.due_date)// calculated status
                    };
            
                    allTasks.push(taskWithDocumentInfo); // Add the enhanced task to the allTasks array

                    let summary = {
                        date: document.creation_date,
                        text: document.extract.summary
                    }

                    allSummaries.push(summary);
                });
            });

            /* setTaskPredictions(allTasks);
            setTasksResultsLoadingStatus(false); */
            return [allTasks, allSummaries];

        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "projects/extract");
            return [[], []];
            //setTasksResultsLoadingStatus(false);
        }
    };

    /**
     * Q&A LLM METHODS
     */
    const postCreateReport = async (source, title, model, response_format, reference_documents, system_instructions, user_instructions) => {
        setQAReportCreationLoading(true);
        //setQAFeedbackMessage(`Uploading File ${file.name}, with size: ${file.size} to Cache.`);
        /* const qc = new AbortController()
        setQAUploadController(qc); */
        const formData = new FormData();
        formData.append('title', title);
        formData.append('system_instructions', system_instructions);
        formData.append('user_instructions', user_instructions);
        formData.append('model', model);
        formData.append('response_format', response_format);

        let jsonPayload = JSON.stringify(reference_documents);
        formData.append('reference_documents_info', jsonPayload);
       /*  reference_documents.forEach((file) => {
            formData.append('reference_documents_info', file);
        }); */
    
        try {
            const response = await http.post(source + 'report', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }/* ,
                  signal: qc.signal */
            });
    
            let api_data = Object.values(response.data);       
            console.log('RE Response: ', api_data);

            setQAReportCreationLoading(false);
            /* setQAUploadLoading(false);
                setQAFeedbackMessage(`File ${file.name} successfully uploaded.`); */

            return {"title": api_data[0], "creation_date": api_data[1], "result":api_data[2], "reference_documents":api_data[3]}; // Return the data so it can be used by the function caller
        } catch (thrown) {
            setQAReportCreationLoading(false);
            /* setQAUploadLoading(false); */
            if (axios.isCancel(thrown)) {
                /* setQAFeedbackMessage(`File upload cancelled.`); */
                console.log('Upload cancelled', thrown.message);
            } else {
                console.log('Upload failed', thrown.message);
                /* setQAFeedbackMessage(`File upload failed.`); */
                throw thrown; // Rethrow the error to allow the caller to catch it
            }
        }
    };

    const uploadReferenceDocuments = async (source, title, files) => {
        /* setQAUploadLoading(true);
        setQAFeedbackMessage(`Uploading File ${file.name}, with size: ${file.size} to Cache.`); */
        /* const qc = new AbortController()
        setQAUploadController(qc); */
        const formData = new FormData();
        formData.append('title', title);
        files.forEach((file) => {
            formData.append('reference_documents', file);
        });

        try {
            const response = await http.post(source + 'upload_references', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }/* ,
                  signal: qc.signal */
            });
    
            let api_data = Object.values(response.data);       
            console.log('RE Response: ', api_data);

            /* setQAUploadLoading(false);
                setQAFeedbackMessage(`File ${file.name} successfully uploaded.`); */

            return api_data[0]; // Return the data so it can be used by the function caller

        } catch (thrown) {
            /* setQAUploadLoading(false); */
            if (axios.isCancel(thrown)) {
                /* setQAFeedbackMessage(`File upload cancelled.`); */
                console.log('Upload cancelled', thrown.message);
            } else {
                console.log('Upload failed', thrown.message);
                /* setQAFeedbackMessage(`File upload failed.`); */
                throw thrown; // Rethrow the error to allow the caller to catch it
            }
        }
    };

    const clearReferenceDocuments = async (source, title) => {
        /* setQAUploadLoading(true);
        setQAFeedbackMessage(`Uploading File ${file.name}, with size: ${file.size} to Cache.`); */
        /* const qc = new AbortController()
        setQAUploadController(qc); */
        const formData = new FormData();
        formData.append('title', title);

        try {
            const response = await http.post(source + 'clear_report_files', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }/* ,
                  signal: qc.signal */
            });
    
            let api_data = Object.values(response.data);       
            console.log('RE Response: ', api_data);

            /* setQAUploadLoading(false);
                setQAFeedbackMessage(`File ${file.name} successfully uploaded.`); */

            return api_data; // Return the data so it can be used by the function caller

        } catch (thrown) {
            /* setQAUploadLoading(false); */
            if (axios.isCancel(thrown)) {
                /* setQAFeedbackMessage(`File upload cancelled.`); */
                console.log('Upload cancelled', thrown.message);
            } else {
                console.log('Upload failed', thrown.message);
                /* setQAFeedbackMessage(`File upload failed.`); */
                throw thrown; // Rethrow the error to allow the caller to catch it
            }
        }
    };

    const uploadQAFileToCache = async (source, file) => {
        setQAUploadLoading(true);
        setQAFeedbackMessage(`Uploading File ${file.name}, with size: ${file.size} to Cache.`);
        /* const qc = new AbortController()
        setQAUploadController(qc); */
        const formData = new FormData();
        formData.append('file', file);
        await http.post(source + `upload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }/* ,
            signal: qc.signal */
        }).then(
            function (res) {
                let api_data = Object.values(res.data);  
                api_data = [api_data[1]]      
                console.log('QA Response: ', api_data);
                setQAUploadLoading(false);
                setQAFeedbackMessage(`File ${file.name} successfully uploaded.`);
            }
        ).catch(
            function(thrown) {
                setQAUploadLoading(false);
                if (axios.isCancel(thrown)) {
                    console.log('Upload cancelled', thrown.message);
                    setQAFeedbackMessage(`File upload cancelled.`);
                } else {
                    console.log('Upload failed', thrown.message);
                    setQAFeedbackMessage(`File upload failed.`);
                }
            }
        )
        
    };

    const getQASearchResults = async (source, query, clear_history=false) => {
        setQAResults([]);
        setQAQueryLoading(true);
        const qc = new AbortController()
        setQAQueryController(qc);
        setQAFeedbackMessage(`Query "${query.slice(0,10)}..." is being processed.`);
        await http.post(source + `search-answer?query=` + query, {}, { 
            signal: qc.signal
        }).then(
            function(response) {
                let api_data = Object.values(response.data);  
                api_data = [api_data[1]]       
                console.log('QA Response, unfiltered: ', api_data);
                if( typeof api_data[0] === 'string' || api_data[0] instanceof String ){
                    setQAResults([]);
                } else {
                    setQAResults(api_data);
                    
                    let history = []
                    if(!clear_history) {
                        history = [{"question":query, "answer":api_data[0]}].concat(qa_history)
                    } else {
                        history = [{"question":query, "answer":api_data[0]}]
                    }

                    setQAHistory(history)
                }
                setQAQueryLoading(false); 
            }
        ).catch(function (thrown) {
            setQAQueryLoading(false);
            if (axios.isCancel(thrown)) {
                console.log('Request cancelled', thrown.message);
                setQAFeedbackMessage(`Query cancelled.`);
            } else {
                console.log('Request failed', thrown.message);
                setQAFeedbackMessage(`Query failed.`);
            }
          });
    };

    // TODO: add error Catching
    const clearQACacheData = async (source) => {
        setQADeleteLoading(true);
        setQAFeedbackMessage("Removing Files from Cache.");
        const res = await http.post(source + `clear`);
        let api_data = Object.values(res.data);  
        api_data = [api_data[1]]       
        console.log('QA Response: ', api_data);
        setQADeleteLoading(false);
        setQAFeedbackMessage("Cache cleared.");
    };

    const getQADocs = async (source) => {
        setQADocs([]);
        //setQAFeedbackMessage(`Retrieving File List from Cache.`);
        await http.get(source + `files`).then(
            function(response) {
                console.log('Response: ', response.data.files);
                setQADocs(response.data.files);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
                setQAFeedbackMessage('Request for File List failed.');
            }
        )
    };

    const abortQAQuery = () => {
        qa_query_controller.abort();
        setQAQueryLoading(false); 
    };

    /* const abortQAUpload = () => {
        qa_upload_controller.abort();
        setQAUploadLoading(false); 
    }; */

    const removeItemFromQAResults = (index) => {
        console.log(`Removing Item ${index} from results list.`)
        let history =  qa_history.slice();
        history.splice(index, 1);
        setQAHistory(history)
    };

    const fetchQAData = async (source, newQuery, clear_history) => {
        await getQASearchResults(source, newQuery, clear_history);
    };

    const fetchReportData = async (title, model, response_format, reference_documents, system_instructions, user_instructions) => {
        console.log('Posting Report: ', title);
        try {
            // Directly await the promise returned by postCreateReport
            const response = await postCreateReport(config.envs[config.active_env].qa_api_url, title, model, response_format, reference_documents, system_instructions, user_instructions);
            console.log('RE data received:', response);
            // Return the response so it can be used by the caller of fetchReportData
            return response;
        } catch (error) {
            // Log and re-throw the error to allow the caller to handle it
            console.error(error);
            throw error;
        }
    };

    const uploadReportDocs = async (title, files) => {
        console.log('Posting References for Report: ', title);
        try {
            // Directly await the promise returned by postCreateReport
            const response = await uploadReferenceDocuments(config.envs[config.active_env].qa_api_url, title, files);
            console.log('RE data received:', response);
            // Return the response so it can be used by the caller of fetchReportData
            return response;
        } catch (error) {
            // Log and re-throw the error to allow the caller to handle it
            console.error(error);
            throw error;
        }
    };

    const clearReportDocs = async (title) => {
        console.log('Clearing References for Report: ', title);
        try {
            // Directly await the promise returned by postCreateReport
            const response = await clearReferenceDocuments(config.envs[config.active_env].qa_api_url, title);
            console.log('RE data received:', response);
            // Return the response so it can be used by the caller of fetchReportData
            return response;
        } catch (error) {
            // Log and re-throw the error to allow the caller to handle it
            console.error(error);
            throw error;
        }
    };

    const queryGenerativeModel = async (messages, response_format="text") => {
        console.log('Querying Model.');
        try {
            const response = await http.post(
                config.envs[config.active_env].domain + config.api_path + `generative-openai/chat-completion`, 
                {
                    //Body
                    "llm_model_name": "gpt-4o",
                    "stream": false,
                    "temperature":0,
                    "options": {
                        "seed" : 0,
                        "max_tokens" : 4096
                    },
                    "response_format": {
                        "type": response_format
                    },
                    "messages": messages
                },    
                {
                    headers: {
                    // Include the Authorization header with token
                    Authorization: `Bearer ${token.accessToken}`,
                    'Tenant': currentTenant/* ,
                    'Path': project_path */
                    }
                }
            );

            let api_data = response.data;
            console.log('Data received:', api_data);
            
            return api_data['Response context'];

        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "generative-model/completion");
            return null;
            //setTasksResultsLoadingStatus(false);
        }
    };

    const clearQAData = async (source) => {
        await clearQACacheData(source);
        await getQADocs(config.envs[config.active_env].qa_api_url);
    };

    const uploadQAData = async (source, file) => {
        await uploadQAFileToCache(source, file);
        await getQADocs(config.envs[config.active_env].qa_api_url);
    };

    const qadocList = async () => {
        console.log('Query Doc List'); 
        await getQADocs(config.envs[config.active_env].qa_api_url);
    }

    const qaSearch = (query, clear_history=false) => {
        console.log('Posting Query: ', query);
        if (query!== null) { 
            fetchQAData(config.envs[config.active_env].qa_api_url, query, clear_history)
            .then(
                console.log('QA data received')
                )
            .catch(console.error);
        }
    };

    const qaClearDocs = () => {
        console.log('Clearing Docs in Cache.');
        clearQAData(config.envs[config.active_env].qa_api_url)
            .then(
                console.log('QA data cleared.')
                )
            .catch(console.error);
    };

    const qaUploadDocument = (file) => {
        console.log('Uploading Document to Cache.');
        if (file!== null) { 
            uploadQAData(config.envs[config.active_env].qa_api_url, file)
            .then(
                console.log('QA data uploaded.')
                )
            .catch(console.error);
        }
    };

    /**
     * Project Management METHODS
     */
    const getProject = async (project_path) => {
        setProjectLoading(true);
        await http.get(config.envs[config.active_env].domain + config.api_path + `projects/details`, {
            headers: {
                // Include the Authorization header with your token
                Authorization: `Bearer ${token.accessToken}`,
                'Tenant': currentTenant,
                /* 'OCS-APIRequest': 'true', */
                'Path': project_path,
              }/* ,
              signal: qc.signal */
        }).then(
            async function(response) {
                let api_data = Object.values(response.data);
                console.log('Project retrieved:', api_data);
                setProjectLoading(false);
                return api_data;
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
                setProjectLoading(false);
            }
        ) 
    };  

    const createOrUpdateProject = async(project_data) => {
        setProjectLoading(true);
        await http.post(config.envs[config.active_env].domain + config.api_path + `projects/update`, project_data, {
            headers: {
                // Include the Authorization header with your token
                Authorization: `Bearer ${token.accessToken}`,
                'Tenant': currentTenant,
                /* 'OCS-APIRequest': 'true' */
              }/* ,
              signal: qc.signal */
        }).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Project created/updated:', api_data);
                setProjectLoading(false);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
                setProjectLoading(false);
            }
        ) 
    };

    const deleteProject = async (project_path) => {
        /* const project_name = project_path.split('/')[2];
        console.log('project_name', project_name); */
        setProjectLoading(true);
        await http.delete(config.envs[config.active_env].domain + config.api_path + `projects/delete`/* ?project_name=` + project_name */, {
            headers: {
                // Include the Authorization header with your token
                Authorization: `Bearer ${token.accessToken}`,
                'Tenant': currentTenant,
                /* 'OCS-APIRequest': 'true', */
                'Path': project_path,
              }/* ,
              signal: qc.signal */
        }).then(
            async function(response) {
                let api_data = Object.values(response.data);
                console.log('Project deleted:', api_data);
                setProjectLoading(false);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
                setProjectLoading(false);
            }
        ) 
    };   
    
    const getUserData = async (tenant) => {
        // Initial state reset can be done synchronously; they don't return promises
        setUserData(null);
        setUserProjects([]);
        setIsLoadingUserData(true);
    
        try {
            const response = await http.get(config.envs[config.active_env].domain + config.api_path + `projects/list-projects`, {
                headers: {
                    // Include the Authorization header with your token
                    Authorization: `Bearer ${token.accessToken}`
                }
            });
            let api_data = Object.values(response.data);
            console.log('Project list for user loaded:', api_data);
    
            const user_details = {
                "user": api_data[0],
                "email": api_data[1]
            };
            setUserData(user_details);
            sessionStorage.setItem('userData', JSON.stringify(user_details));
            
            const allPaths = [...new Set(api_data[2].map(({ path }) => path))];
            let paths = [];
            if (tenant) {
                paths = allPaths.filter(path => {
                    // Extract the part of the path between the first two forward slashes
                    const pathTenant = path.split('/')[1];
                    // Check if this part matches the tenant name
                    return pathTenant === tenant;
                });
            } else {
                paths = allPaths;
            }
            setUserProjects(paths);
            sessionStorage.setItem('userProjectPaths', JSON.stringify(paths));

            setIsLoadingUserData(false);
            
            return paths; 
        } catch (error) {
            console.log('Request failed', error.message);
            setUserData(null);
            setUserProjects([]);
            setIsLoadingUserData(false);
            handleApiError(error, logout, "projects/list-projects");
        }
    };

    const getProjectDetailList = async ({ 
        tenant = currentTenant, 
        projects = userProjects 
    } = {}) => {
        await setProjects([]);

        // Check if tenant and projects are valid
        if (!tenant || !projects || projects.length === 0 || !token) {
            console.log('Invalid tenant or projects.');
            return [];
        }

        await setProjectLoading(true);

        // Add abort Controller
        //const qc = new AbortController(); // Assuming you uncomment the signal part
    
        try {
            const response = await http.get(config.envs[config.active_env].domain + config.api_path + `projects/details`, {
                headers: {
                    Authorization: `Bearer ${token.accessToken}`,
                    'Tenant': tenant,
                    'Paths': projects.join(',')
                },
                //signal: qc.signal
            });
    
            let api_data = Object.values(response.data);
            console.log('Projects loaded:', api_data);
            setProjects(api_data);
            setProjectLoading(false);
            return api_data;
            
        } catch (error) {
            console.log('Request failed', error.message);
            setProjectLoading(false);
            handleApiError(error, logout, "projects/details");
        }
    }
    

    /**
     * Mock Topic Data
     */
    const getMockTopics = async () => {
        try {
            /* const response = await http.get(config.mock_server_api_url + `topics`, {
            headers: {
                // Include the Authorization header with your token
                Authorization: `Bearer ${token.accessToken}`,
                'Tenant': currentTenant,
                'Path': project_path
                }
            }); */

            /* let api_data = response.data;
            console.log('Data received:', api_data); */

            let api_data = config.test_data.acceptance_criteria;
            console.log('Topics: ', api_data);
            return api_data;

        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "projects/mock-topics");
            return null;
            //setTasksResultsLoadingStatus(false);
        }
    };

    /**
     * ReportTemplates Management METHODS
     */
    const getAcceptanceCriteria = async() => {
        try {
            
            const response = await http.get(config.envs[config.active_env].domain + config.api_path + `acceptance-criteria/search`, {
            headers: {
                // Include the Authorization header with token
                Authorization: `Bearer ${token.accessToken}`,
                /* 'Tenant': currentTenant,
                'Path': project_path */
                }
            });

            let api_data = Object.values(response.data);
            console.log('AC Data received:', api_data);

            return api_data;

        } catch (error) {
            console.log('Request for ACs failed', error.message);
            handleApiError(error, logout, "acceptance-criteria/search");
            return [];
        }
    }


    // TODO: Check if instances use template_id, In use Templates cannot be deleted.
    const createReportInstance = async (template_data) => {
        await http.post(config.mock_server_api_url + `report_instances`, template_data).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Instance created:', api_data);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
            }
        ) 
    };

    // TODO: Check if instances use template_id, In use Templates cannot be deleted.
    const updateReportInstance = async (instance_id, template_data) => {
        await http.put(config.mock_server_api_url + `report_instances/${instance_id}`, template_data).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Instance updated:', api_data);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
            }
        ) 
    };

    const generateReportResult = async(report_definition_name, project) => {
        try {
            // Initialize params object
            let queryParams = {
                'name':report_definition_name,
            };

            const response = await http.post(config.envs[config.active_env].domain + config.api_path + `generate-report/generate-report`, {
                /* "name": report_definition_name, */
                /* "project": project, */
                /* "Tenant": currentTenant */
            }, {
                headers: {
                    Authorization: `Bearer ${token.accessToken}`,
                    'Tenant': currentTenant,
                    'Paths': `/${currentTenant}/${project}`
                    },
                    params: queryParams
                }
            );
            let api_data = Object.values(response.data);
            console.log('Report Results loaded:', api_data);
            return api_data;

        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "generate-report/generate-report");
            return [];
        }
    };

    const getFolderListStateless = async(project, recursive, include_dirs) => {
        try {
            // Initialize params object
            let params = {};
            if (recursive && include_dirs) {
                params.recursive = recursive;
                params.include_dirs = include_dirs;
            }

            const response = await http.get(config.envs[config.active_env].domain + config.api_path + `report-mgmt/list-files`, {
                headers: {
                    Authorization: `Bearer ${token.accessToken}`,
                    'Tenant': currentTenant,
                    'Paths': `/${currentTenant}/${project}`
                    },
                    params: params  // Pass params object to the HTTP request
            });
            let api_data = Object.values(response.data);
            console.log('List Files loaded:', api_data);
            return api_data;
        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "report-mgmt/list-files");
            return [];
        }
    };

    const createReportDefinition = async(report_definition_data) => {
        try {
            const response = await http.post(config.envs[config.active_env].domain + config.api_path + `report-mgmt/create`, report_definition_data, {
                headers: {
                    // Include the Authorization header with token
                    Authorization: `Bearer ${token.accessToken}`,
                    'Tenant': currentTenant,
                    'Paths': `/${currentTenant}/${report_definition_data.project}`
                }
            } );
            let api_data = Object.values(response.data);
            console.log('Report Results loaded:', api_data);
            return api_data;

        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "report-mgmt/create");
            return [];
        }
    };

    const removeReportResult = async(project, name, creation_date) => {
        try {
            // Initialize params object
            let params = {};
            if (project && name && creation_date) {
                params.project = project;
                params.name = name;
                params.creation_date = creation_date;
            }
    
            const response = await http.delete(config.envs[config.active_env].domain + config.api_path + `report-mgmt/report`, {
                headers: {
                    // Include the Authorization header with token
                    Authorization: `Bearer ${token.accessToken}`,
                    'Tenant': currentTenant,
                    'Paths': `/${currentTenant}/${project}`
                },
                params: params  // Pass params object to the HTTP request
            } );
            let api_data = Object.values(response.data);
            console.log('Report Results loaded:', api_data);
            return api_data;

        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "report-mgmt/report");
            return [];
        }
    };

    const removeReportDefinition = async(project, name) => {
        try {
            // Initialize params object
            let params = {};
            if (project && name) {
                params.project = project;
                params.name = name;
            }
            
            const response = await http.delete(config.envs[config.active_env].domain + config.api_path + `report-mgmt/report_definition`, {
                headers: {
                    // Include the Authorization header with token
                    Authorization: `Bearer ${token.accessToken}`,
                    'Tenant': currentTenant,
                    'Paths': `/${currentTenant}/${project}`
                },
                params: params  // Pass params object to the HTTP request
            } );
            let api_data = Object.values(response.data);
            console.log('Report Results loaded:', api_data);
            return api_data;

        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "report-mgmt/report_definition");
            return [];
        }
    };

    const fetchReportResults = async(project, name, creation_date) => {
        try {

            // Initialize params object
            let params = {};
            if (name && creation_date) {
                params.name = name;
                params.creation_date = creation_date;
            }

            let paths = "";
            if (typeof project !== "undefined" && project !== null) {   
                paths = `/${currentTenant}/${project}`;
            } else {
                paths = userProjects;
            }

            if (Array.isArray(paths) && paths.length === 0) {
                return [];
            }

            const response = await http.get(config.envs[config.active_env].domain + config.api_path + `report-mgmt/get/report`, {
                headers: {
                    Authorization: `Bearer ${token.accessToken}`,
                    'Tenant': currentTenant,
                    'Paths': `${paths}`
                    },
                    params: params  // Pass params object to the HTTP request
            });
            let api_data = Object.values(response.data);
            console.log('Report Results loaded:', api_data);
            return api_data;

        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "report-mgmt/get/report");
            return [];
        }
    };

    const createAcceptanceCriteria = async(acceptance_criteria_data) => {
        try {
            const response = await http.post(config.envs[config.active_env].domain + config.api_path + `acceptance-criteria/create`, acceptance_criteria_data, {
                headers: {
                    // Include the Authorization header with token
                    Authorization: `Bearer ${token.accessToken}`,
                    'Tenant': currentTenant,
                    'Paths': ''
                }
            } );
            let api_data = Object.values(response.data);
            console.log('AC Created:', api_data);
            return api_data;

        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "acceptance-criteria/create");
            return [];
        }
    };

    const editAcceptanceCriteria = async(acceptance_criteria_data) => {
        try {
            const response = await http.put(
                config.envs[config.active_env].domain + config.api_path + 'acceptance-criteria/edit', 
                acceptance_criteria_data, 
                {
                    headers: {
                        Authorization: `Bearer ${token.accessToken}`,  
                        'Tenant': currentTenant,
                        'Paths':''
                    },
                    // params
                }
            );
            let api_data = Object.values(response.data);
            console.log('AC Edited:', api_data);
            return api_data;

        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "acceptance-criteria/edit");
            return [];
        }
    };

    const removeAcceptanceCriteria = async( name) => {
        try {
            // Initialize params object
            let params = {};
            if (name) {
                params.name = name;
            }
            
            const response = await http.delete(config.envs[config.active_env].domain + config.api_path + `acceptance-criteria/delete`, {
                headers: {
                    // Include the Authorization header with token
                    Authorization: `Bearer ${token.accessToken}`,
                    'Tenant': currentTenant,
                    /* 'Paths': '' */
                },
                params: params  // Pass params object to the HTTP request
            } );
            let api_data = Object.values(response.data);
            console.log('AC Removed:', api_data);
            return api_data;

        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "acceptance-criteria/delete");
            /* if(error.response.status === 409) {
                alert(error.response.data.detail)
            } */
            return [];
        }
    };

    const fetchReportDefinitions = async (project, name) => {
        try {
            // Initialize params object
            let params = {};
            if (name) {
                params.name = name;
            }
    
            let paths = "";
            if (typeof project !== "undefined" && project !== null) {
                paths = `/${currentTenant}/${project}`;
            } else {
                paths = userProjects;
            }

            if (Array.isArray(paths) && paths.length === 0) {
                return [];
            }

            const response = await http.get(config.envs[config.active_env].domain + config.api_path + `report-mgmt/get/report_definition`, {
                headers: {
                    Authorization: `Bearer ${token.accessToken}`,
                    'Tenant': currentTenant,
                    'Paths': `${paths}`
                },
                params: params  // Pass params object to the HTTP request
            });
            let api_data = Object.values(response.data);
            console.log('Report Definitions loaded:', api_data);
            return api_data;
        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "report-mgmt/get/report_definition");
            return [];  // Correctly handle the error case by returning an empty array
        }
    };

    const editReportDefinition = async(report_definition_data) => {
        try {
            const response = await http.put(
                config.envs[config.active_env].domain + config.api_path + 'report-mgmt/edit/report_definition', 
                report_definition_data, 
                {
                    headers: {
                        Authorization: `Bearer ${token.accessToken}`,  
                        'Tenant': currentTenant,
                        'Paths': `/${currentTenant}/${report_definition_data.project}`
                    },
                    // params
                }
            );
            let api_data = Object.values(response.data);
            console.log('Report Results loaded:', api_data);
            return api_data;

        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "report-mgmt/edit/report_definition");
            return [];
        }
    };

    const editReportResult = async(project, name, creation_date, text) => {
        try {

            // Initialize params object
            let queryParams = {
                'project':project,
                'creation_date':creation_date,
                'name':name,
                'text':text
            };

            const response = await http.put(
                config.envs[config.active_env].domain + config.api_path + 'report-mgmt/edit/report', 
                null,  // No body for PUT request
                {
                    headers: {
                        Authorization: `Bearer ${token.accessToken}`,  // Ensure token.accessToken is valid
                        'Tenant': currentTenant,  // Ensure currentTenant is defined
                        'Paths': `/${currentTenant}/${project}`
                    },
                    params: queryParams  // Pass query params object to the HTTP request
                }
            );
            let api_data = Object.values(response.data);
            console.log('Report Results loaded:', api_data);
            return api_data;

        } catch (error) {
            console.log('Request failed', error.message);
            handleApiError(error, logout, "report-mgmt/get/report");
            return [];
        }
    };
    

    const getReportTemplatesList = async() => {
        setReportTemplates([]);
        setReportTemplatesLoading(true);

        // Add abort Controller
        // Add Feedback Message
        await http.get(config.mock_server_api_url + `report_templates`).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Templates loaded:', api_data);
                setReportTemplates(api_data);
                setReportTemplatesLoading(false);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
                setReportTemplatesLoading(false);
            }
        ) 
    }

    const createReportTemplate = async(template_data) => {
        await http.post(config.mock_server_api_url + `report_templates`, template_data).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Template created:', api_data);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
            }
        ) 
    };

    const updateReportTemplate = async(template_id, template_data) => {
        await http.put(config.mock_server_api_url + `report_templates/` + template_id, template_data).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Template updated:', api_data);   
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
            }
        ) 
    };

    // TODO: Check if instances use template_id, In use Templates cannot be deleted.
    const removeReportTemplate = async (template_id) => {
        await http.delete(config.mock_server_api_url + `report_templates/${template_id}`).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Template deleted:', api_data);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
            }
        );
    };

    /**
     * ReportInstances Management METHODS
     */
    const getReportInstanceById = async(instance_id) => {
        const res = await http.get(config.mock_server_api_url + `report_instances/${instance_id}`);
        let api_data = res.data;
        console.log('Report Instance:', api_data);
        return api_data;
    }

    const fetchReportInstances = async() => {
        // Add abort Controller
        // Add Feedback Message
        //?title=json-server&author=typicode
        try {

            const response = await http.get(config.mock_server_api_url + `report_instances`);
            let api_data = Object.values(response.data);
            console.log('Report Instances loaded:', api_data);
            return api_data;

        } catch (error) {
            console.log('Request failed', error.message);
            return [];
        }
    };

    const getReportInstancesList = async() => {
        setReportInstances([]);
        setReportInstancesLoading(true);

        // Add abort Controller
        // Add Feedback Message
        //?title=json-server&author=typicode
        await http.get(config.mock_server_api_url + `report_instances`).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Instances loaded:', api_data);
                setReportInstances(api_data);
                setReportInstancesLoading(false);
                return api_data;
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
                setReportInstancesLoading(false);
            }
        ) 
    }

    // TODO: Check if instances use template_id, In use Templates cannot be deleted.
    const removeReportInstance = async (instance_id) => {
        await http.delete(config.mock_server_api_url + `report_instances/${instance_id}`).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Instance deleted:', api_data);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
            }
        );
    };
    
    /**
     * Document Search STATE
     */  

    const getSearchResultsInDocsStatelessV2 = async (source, top_k, inner_hits_size, project, query) => {
        const project_list = project == 'all' ? userProjects.join(',') : project; 
        try {
            const res = await http.post(source + `documents/search`, {
                    /* "directory": `${directory}`, */
                    "top_k":top_k,
                    "inner_hits_size": inner_hits_size,
                    "phrase":query
            }, {
                headers: {
                    Authorization: `Bearer ${token.accessToken}`,
                    'Tenant': currentTenant,
                    'Paths': project_list,
                    }
            });

            const api_data = Object.values(res.data);         
            console.log('ES Response STATELESS, unfiltered: ', api_data[0]);        
            return api_data[0];
        } catch (error) {
            console.error('Error fetching documents:', error);
            handleApiError(error, logout, "documents/search");
        }
    }

    const getSearchResultsInDocsStateless = async (source, top_k, inner_hits_size, project, query, directory="/") => {
        const project_list = project == 'all' ? userProjects.join(',') : project; 
        const res = await http.post(source + `search-document`, {
                "directory": `${directory}`,
                "top_k":top_k,
                "inner_hits_size": inner_hits_size,
                "phrase":query
        }, {
            headers: {
                Authorization: `Bearer ${token.accessToken}`,
                'Tenant': currentTenant,
                'Paths': project_list,
                }
        });

        const api_data = Object.values(res.data);         
        console.log('ES Response STATELESS, unfiltered: ', api_data[0]);        
        return api_data[0];
    }

    const getSearchResultsInDocsV2 = async (source, top_k, inner_hits_size, project, query) => {
        setDocResults([]);
        setDocsResultsLoadingStatus(true);
        const project_list = project == 'all' ? userProjects.join(',') : project; 
        if(config.use_mock_data){
            setDocResults([]);
            setDocsResultsLoadingStatus(false);
        } else {
            try {
                const res = await http.post(source + `documents/search`, {
                    "top_k": top_k,
                    "inner_hits_size": inner_hits_size,
                    "phrase": query
                }, {
                    headers: {
                        Authorization: `Bearer ${token.accessToken}`,
                        'Tenant': currentTenant,
                        'Paths': project_list,
                    }
                });
    
                const api_data = Object.values(res.data);
                console.log('ES Response, unfiltered: ', api_data[0]);
                setDocResults(api_data[0]);
                setDocsResultsLoadingStatus(false);
            } catch (error) {
                console.error('Error fetching documents:', error);
                setDocsResultsLoadingStatus(false);
                handleApiError(error, logout, "documents/search");
                /* if (error.response && error.response.status === 500) {
                    // Handle server errors
                    console.error('Server error occurred');
                    alert('A server error occurred. Please try again later.');
                } else if (error.response && error.response.status === 401) {
                    // Handle unauthorized errors, potentially due to expired JWT
                    console.error('Authorization issue, check your login status.');
                    alert('Your session has expired. Please log in again.');
                } */
            }
        }    
    }

    const getSearchResultsInDocs = async (source, top_k, inner_hits_size, project, query, directory="/") => {
        setDocResults([]);
        setDocsResultsLoadingStatus(true);
        const project_list = project == 'all' ? userProjects.join(',') : project; 
        if(config.use_mock_data){
            setDocResults([]);
            setDocsResultsLoadingStatus(false);
        } else {
            
            const res = await http.post(source + `search-document`, {
                    "directory": `${directory}`,
                    "top_k":top_k,
                    "inner_hits_size": inner_hits_size,
                    "phrase":query
            }, {
                headers: {
                    // Include the Authorization header with your token
                    Authorization: `Bearer ${token.accessToken}`,
                    'Tenant': currentTenant,
                    'Paths': project_list,
                  }/* ,
                  signal: qc.signal */
            });

            const api_data = Object.values(res.data);         
            console.log('ES Response, unfiltered: ', api_data[0]);
            
            setDocResults(api_data[0]);
            setDocsResultsLoadingStatus(false);
        }    
    }
    
    const documentSearchV2 = (project, query, top_k, inner_hits_size) => {
        console.log('Posting Project:', project, ' Query: ', query);
        
        if (query!== null && project!== null) {

            getSearchResultsInDocsV2(config.envs[config.active_env].domain + config.api_path, top_k, inner_hits_size, project, query)
                .then(
                    console.log('ES data received')
                    )
                .catch(
                    console.error
                ); 
        }
    };

    const documentSearch = (project, query, top_k, inner_hits_size, directory) => {
        console.log('Posting Project:', project, ' Query: ', query);
        
        if (query!== null && project!== null) {

            getSearchResultsInDocs(config.envs[config.active_env].domain + config.api_path, top_k, inner_hits_size, project, query, directory)
                .then(
                    console.log('ES data received')
                    )
                .catch(console.error); 
        }
    };

    /************************************************************************************* */

    /**
     * DATA
     */

    const data = {
        userData, isLoadingUserData , setIsLoadingUserData, userDataError, getUserData, 
        userProjects, userTenants, setUserProjects, setUserData, 

        top_k, setTop_k, project, setProject, query, setQuery,
        drawerOpen, setDrawerOpen, activeTab, setActiveTab, activePage, setActivePage, pageTabs, setPageTabs,

        getSearchResultsInDocsStateless, getSearchResultsInDocsStatelessV2, documentSearch, documentSearchV2, doc_results, setDocResults, docsResultsLoading, setDocsResultsLoadingStatus,
        startDate, setStartDate, endDate, setEndDate, searchParams, setSearchParams,
       
        qa_results, setQAResults, qaSearch, qaClearDocs, qaUploadDocument, qa_history, setQAHistory, 
        qadocList, qa_docs, abortQAQuery, removeItemFromQAResults, qaFeedbackMessage, setQAFeedbackMessage,
        qaQueryLoading, setQAQueryLoading, qaDeleteLoading, setQADeleteLoading, qaUploadLoading, setQAUploadLoading, 
       
        task_predictions, getExtractedData, tasksResultsLoading, setTasksResultsLoadingStatus, getCalculatedData, 

        queryGenerativeModel, getFolderListStateless,

        projects, setProjects, projectsLoading, deleteProject, getProject, createOrUpdateProject, getProjectDetailList, 

        reportDefinitionData, setReportDefinitionData, 
        reportTemplates, getReportTemplatesList, createReportTemplate, createReportInstance, updateReportTemplate, removeReportTemplate, reportTemplatesLoading,
        reportInstances, getReportInstancesList, fetchReportInstances, reportInstancesLoading, removeReportInstance, updateReportInstance,
        getReportInstanceById,

        fetchReportDefinitions, fetchReportResults, createReportDefinition, generateReportResult, removeReportResult, removeReportDefinition, editReportResult, editReportDefinition, 

        getAcceptanceCriteria, createAcceptanceCriteria, editAcceptanceCriteria, removeAcceptanceCriteria,

        fetchReportData, uploadReportDocs, clearReportDocs, qaReportCreationLoading, setQAReportCreationLoading,

        getMockTopics
    }

    return(
        <AppContext.Provider value={data}>
            {children}
            {/* <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
        >
            <Alert onClose={() => setOpenSnackbar(false)} severity="warning" sx={{ width: '100%' }}>
                {snackbarMessage}
            </Alert>
        </Snackbar> */}
        </AppContext.Provider>
    )
};