import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, TextField } from '@mui/material';
import ACDocumentSearch from './ACDocumentSearch';
import ACFolderSearch from './ACFolderSearch';

const SearchToggle = ({ initialIsDocumentSearch, onSelect, onClearSelected, project /* onDateChange, initialFilterStartDate */  }) => {
  // Calculate the number of days from initialFilterStartDate to today
  /* const calculateDaysAgo = (filterStartDate) => {
    if (!filterStartDate) return 7; // Default to 7 days if no valid date is provided

    const startDate = new Date(filterStartDate);
    if (isNaN(startDate.getTime())) return 7; // Return default 7 days if the date is invalid

    const today = new Date();
    const timeDiff = today - startDate;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    return daysDiff > 0 ? daysDiff : 7; // Default to 7 if the calculated value is non-positive
  }; */

  const [isDocumentSearch, setIsDocumentSearch] = useState(initialIsDocumentSearch);
  //const [filterDaysAgo, setFilterDaysAgo] = useState(initialFilterStartDate ? calculateDaysAgo(initialFilterStartDate) : 7); // Default to 7 days

  const toggleSearch = () => {
    setIsDocumentSearch((prev) => !prev);
  };

  /* useEffect(() => {
    // Ensure the component state is in sync with the prop if the parent updates it
    setIsDocumentSearch(initialIsDocumentSearch);
  }, [initialIsDocumentSearch]); */

  /* useEffect(() => {
    if (!isDocumentSearch) {
      // If it's in Ordnersuche, calculate the filter date on load or switch
      const today = new Date();
      const pastDate = new Date();
      pastDate.setDate(today.getDate() - filterDaysAgo);
      if (!isNaN(pastDate.getTime())) {
        onDateChange(pastDate.toISOString());
      }
    }
  }, [isDocumentSearch, filterDaysAgo, onDateChange]); */
  
  /* const handleDateChange = (event) => {
    const days = parseInt(event.target.value, 10);
    setFilterDaysAgo(days);

    const today = new Date();
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - days);
    if (!isNaN(pastDate.getTime())) {
      onDateChange(pastDate.toISOString()); // Send updated date only if it's valid
    }
  }; */

  return (
    <Box>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">
          {isDocumentSearch ? 'Dokumentensuche' : 'Ordnersuche'}
        </Typography>
        <Button variant="contained" color="primary" onClick={toggleSearch}>
          {isDocumentSearch ? 'Zu Ordnersuche wechseln' : 'Zu Dokumentensuche wechseln'}
        </Button>
      </Box>

      <Box>
        {isDocumentSearch ? 
        <ACDocumentSearch 
          onSelect={onSelect} 
          onClearSelected={onClearSelected} 
          project={project}
        /> : (
          <>
              <ACFolderSearch 
              onSelect={onSelect} 
              onClearSelected={onClearSelected} 
              project={project}
            />
            {/* Filter by past days field */}
            {/* <Box mt={2} display="flex" justifyContent="flex-end">
            <TextField
              label="Filter nach Tagen"
              type="number"
              value={filterDaysAgo}
              onChange={handleDateChange}
              inputProps={{ min: 1 }}
            />
            </Box> */}
          </>
        )}
      </Box>
    </Box>
  );
};

export default SearchToggle;
